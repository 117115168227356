import React, { useState, useEffect, useRef } from 'react';
import PerPic from '../../img/personal_pic.jpg';
import styled from "styled-components";
import Typed from 'react-typed';
import MusicPrompt from './MusicPrompt';
import 'sal.js/dist/sal.css';
import MusicPlayer from './MusicPlayer';
import audio from '../../audio/music.mp3'
import useViewportWidth from '../Hook/useViewportWidth';

const FirstContainer = styled.div`
    background: linear-gradient(to right, white, black);
    width: 100%;
    height: max-content;
    min-height: calc(100vh - 80px);
    align-items: center;
    position: relative;
    display: flex;
    box-sizing: border-box;
`;

const Img = styled.div`
    height: calc(100vh - 80px);
    filter: grayscale(100%);
    background-color: white;
    mix-blend-mode: multiply;
    border-bottom-right-radius: 46%;
    width: 45%;
    min-width: calc(100vh - 80px);
    background: url(${PerPic});
    background-attachment: fixed;
    background-size: contain;
    @media (max-width: 1450px) {
        min-width: auto;
        width: 100%;
        border-bottom-right-radius: unset;
        height: calc(100vh - 80px);
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 45%;
    }
`;

const Container = styled.div`
    width: 55%;
    height: calc(100% - 80px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    --sal-duration: 1s;
    @media (max-width: 1450px) {
        width: 100%;
        position: absolute;
        align-items: center;
    }
    @media (max-width: 750px) {
        transform: translateY(-7vh) !important;
    }
`;

const H1 = styled.div`
    background-color: ${props => props.bcolor};
    font-family: ${props => props.font};
    width: ${props => props.width};
    height: max-content;
    text-align: ${props => props.align};
    font-size: 2.5rem;
    font-weight: 800;
    color: ${props => props.color};
    @media (max-width: 1450px) {
        text-align: center;
        font-size: 2rem;
    }
`;

const H2 = styled.div`
    width: 90%;
    text-align: right;
    margin-top: 5px;
    font-size: 1.25rem;
    font-weight: 100;
    color: white;
    font-family: 'Martel Sans', sans-serif;
    @media (max-width: 1450px) {
        text-align: center;
        font-size: 0.95rem;
    }
`;

const H3 = styled.div`
    font-family: ${props => props.font};
    width: 90%;
    text-align: right;
    font-size: 1.5rem;
    letter-spacing: 0.25rem;
    font-weight: 100;
    color: white;
    @media (max-width: 1450px) {
        text-align: center;
        font-size: 1rem;
        letter-spacing: 0.5rem;
    }
`;

const Intro = () => {
    const width = useViewportWidth();
    const audioPlayer = useRef(); 
    const [isPlaying, setIsPlaying] = useState(0);
    const [showPrompt, setShowPrompt] = useState(true);

    useEffect(() => {
        audioPlayer.current.volume = 0.3
    }, [])

    useEffect(() => {
        setIsPlaying(!audioPlayer.current.paused)
    }, [audioPlayer.current])

    const togglePlaying = () => {
        if (audioPlayer.current.paused) {
            audioPlayer.current.play();
            setShowPrompt(false)
        } 
        else {
            audioPlayer.current.pause();
        }
        setIsPlaying(!audioPlayer.current.paused)
    }
    return (
        <FirstContainer>
            <Img />
            <Container
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease-out-quad"
            >
                <H3>WELCOME!</H3>
                <H1 color="white" width="90%" align="right" font="'Playfair Display', serif" bcolor="unset">Hello, I'm {" "}
                    {(width <= 650 ? "Mirror" : 
                        <Typed
                            strings={[
                            'Nguyen Nguyen Khuong',
                            'Mirror']}
                            typeSpeed={90}
                            backSpeed={90}
                            loop >
                        </Typed>
                    )}
                    
                </H1>
                <H2>FRONTEND DEVELOPER AND DATA ANALYST</H2>
                <MusicPlayer audioPlayer={audioPlayer} togglePlaying={togglePlaying} isPlaying={isPlaying}/>
            </Container>
            <MusicPrompt onAllowed={togglePlaying} showPrompt={showPrompt} setShowPrompt={setShowPrompt}/>
        </FirstContainer>
    )
}

export default Intro