import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";

const SecondContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    box-sizing: border-box;
    background-color: black;
`;

const Sidebar = styled.aside`
    width: 25%;
    min-width: 200px;
    height: max-content;
    position: sticky;
    margin-top: 3%;
    top: 35%;
    @media (max-width: 1024px) {
        display: none;
    }
`;

const SidebarButton = styled.button`
    display: block;
    color: white;
    background-color: unset;
    border: none;
    width: max-content;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 22px;
    text-align: right;
    margin-top: 20px;
    margin-right: 35px;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    &:focus{
        outline: none;
    }
    &::after{
        content: "";
        transition: width 0.3s ease-in-out;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: ${props => props.content ? "90%" : '0'};
        height: 3px;
        background-color: white;
        transform: translateX(-50%);
    }
    &:hover::after,
    &:focus::after {
        content: "";
        width: 90%;
    }
`;

const Section = styled.div`
    width: 75%;
    height: max-content;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const PartialContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    justify-content: center;
    @media (max-width: 1024px) {
        height: max-content;
        padding: 55px 0;
    }
`;

const H1WithLineAbove = styled.div`
    background-color: ${props => props.bcolor};
    font-family: ${props => props.font};
    width: ${props => props.width};
    height: max-content;
    text-align: ${props => props.align};
    font-size: 39px;
    font-weight: 800;
    color: ${props => props.color};
    padding: 0 0 65px;
    --sal-duration: 1s;
    position: relative;
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: -10%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 99%;
        height: 10px;
        background-color: ${props => props.lColor};;
    }
`;

const Mortarboard = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="25" 
            fill="currentColor" 
            className="bi bi-mortarboard-fill" 
            viewBox="0 0 16 16" 
            style={{
                "color": "white",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                margin: "auto"
            }}
        >
                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z"/>
                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z"/>
        </svg>
    )
}

const Award = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="25" 
            fill="currentColor" 
            class="bi bi-award-fill" 
            viewBox="0 0 16 16"
            style={{
                "color": "white",
                position: "absolute",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                margin: "auto"
            }}
        >
            <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
        </svg>
    )
}

const Circle = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    position: relative;
    min-width: 50px;
    min-height: 50px;
`;

const MortarboardMark = () => {
    return (
        <Circle>
            <Mortarboard></Mortarboard>
        </Circle>
    )
}

const AwardMark = () => {
    return (
        <Circle>
            <Award></Award>
        </Circle>
    )
}

const SectionContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 85px;
    align-items: flex-start;
    align-self: flex-start;
    margin: 0;
    --sal-duration: 1s;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: calc(0.7vw + 12px);
    justify-content: space-between;
    @media (max-width: 900px) {
      text-align: justify;
    }
`;

const Year = styled.div`
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
`;

const Title = styled.div`
    font-family: 'Martel Sans', sans-serif;
    font-weight: 900;
    font-size: calc(0.7vw + 12px);
`;

const SubHeading = styled.div`
    font-family: 'Martel Sans', sans-serif;
    font-weight: 900;
    font-size: calc(0.5vw + 12px);
`;

const Note = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: calc(0.5vw + 10px);
`;

const Skills = styled.div`
    display: grid;
    width: 85%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 9vh 10vw;
    justify-self: center;
    @media (max-width: 750px) {
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(1, 1fr);
        gap: 9vh 5vw;
    }
`;

const SkillContainer = styled.div`
    display: flex;
    justify-content: space-around;
    position: relative;
    --sal-duration: 1s;
    align-items: center;
`;

const SkillName = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: black;
    width: 145px;
    font-size: 1.25rem;
`;

const Bar = styled.div`
    width: 12.5vw;
    height: 12px;
    background-color: gray;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        width: ${props => props.level * 2.5}vw;
        height: 12px;
        background-color: black;
    }
    @media (max-width: 750px) {
        width: 25vw;
        &::after{
            width: ${props => props.level * 5}vw;
        }
    }
`;

const Skill = ({name, level}) => {
    return (
        <SkillContainer
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease-out-quad"
        >
            <SkillName>{name}</SkillName>
            <div
                style={{
                    "display": "flex",
                    "justifyContent": "space-between",
                    "position": "relative",
                }}
            >
            <Bar level={level}></Bar>
            </div>
        </SkillContainer>
    )
}

const SectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 7.5vh;
    width: 85%;
    padding: 0 25px;
    box-sizing: border-box;
`

const EducationSection = ({startYear, endYear, title, location, note}) => {
    return (
        <SectionContainer
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease-out-quad"
        >
            <MortarboardMark />
            <Info>
                <Year>{startYear + " - " + endYear}</Year>
                <Title>{title}</Title>
                <SubHeading>{location}</SubHeading>
                {note.map((element) => {
                    return <Note>{element}</Note>
                })}
            </Info>
        </SectionContainer>
    )
}

const AwardSection = ({year, title, organizationalUnit = undefined}) => {
    return (
        <SectionContainer
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease-out-quad"
        >
            <AwardMark />
            <Info>
                <Year>{year}</Year>
                <Title>{title}</Title>
                {
                    organizationalUnit != undefined 
                    ? <SubHeading>Organizational Unit: {organizationalUnit}</SubHeading>
                    : undefined
                }
            </Info>
        </SectionContainer>
    )
}

const Resume = () => {

    const [activeButton, setActiveButton] = useState(-1);

    const handleScroll = () => {
        const sections = document.querySelectorAll('.section');
        sections.forEach((section, index) => {
        const sectionTop = section.offsetTop + 200;
        const sectionBottom = sectionTop + section.offsetHeight;
        const scrollPosition = window.scrollY;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setActiveButton(index);
        }
        });
    };

    const handleClick = (elementId) => {
        const element = document.getElementById(elementId);
        console.log(element);
        if (element) {
            window.scroll({top: element.getBoundingClientRect().top + window.scrollY + 1, behavior: 'smooth'});
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <SecondContainer id="resume">
            <Sidebar>
                <SidebarButton content={activeButton === 0} onClick={() => handleClick("education")}>Education</SidebarButton>
                <SidebarButton content={activeButton === 1} onClick={() => handleClick("skill")}>Skill</SidebarButton>
                <SidebarButton content={activeButton === 2} onClick={() => handleClick("award")}>Awards</SidebarButton>
            </Sidebar>
            <Section>

                <PartialContainer className='section' id="education">
                    <H1WithLineAbove 
                        width="max-content" 
                        align="center" 
                        font="'Playfair Display', serif" 
                        bcolor="unset" 
                        color="black" 
                        lColor="black" 
                        data-sal="slide-down"
                        data-sal-delay="300"
                        data-sal-easing="ease-out-quad"
                    >
                        Education
                    </H1WithLineAbove>
                    <SectionsContainer>
                        <EducationSection 
                            title={"Bachelor of Information Technology"} 
                            startYear={"10/2021"} 
                            endYear={"present"} 
                            location={"RMIT University - Ho Chi Minh City, Vietnam"}
                            note={[
                                    "Current GPA - 3.5",
                                    "- Prominent Coursework: ",
                                    <div>
                                        &emsp;&emsp;+ Embeded Software Engineer – Lead Frontend Developer
                                        in <strong>Intro to Computer System</strong> 
                                    </div>,
                                    <div>
                                        &emsp;&emsp;+ Lead Software Developer in <strong>Advanced Programming Techniques</strong> 
                                    </div>,
                                    <div>
                                        &emsp;&emsp;+ Lead Frontend Developer in <strong>Building IT System</strong> 
                                    </div>,
                                    <div>
                                        &emsp;&emsp;+ Devops-Fullstack Developer – Algorithm Designer for <strong>Capstone Project - Waste Management System</strong>
                                    </div>
                            ]}
                        />
                        <EducationSection 
                            title={"High School Diploma"} 
                            startYear={"07/2018"} 
                            endYear={"07/2021"} 
                            location={"Le Thanh Tong Gifted High School - Hoi An, Vietnam"}
                            note={[
                                    "Final Year GPA - 9.6",
                                    "- Prominent Activities: ",
                                    <div>
                                        &emsp;&emsp;+ Member of <strong>Le Thanh Tong Multimedia</strong> 
                                    </div>,
                            ]}
                        />
                    </SectionsContainer>
                </PartialContainer>

                <PartialContainer className='section' id="skill">
                    <H1WithLineAbove 
                        width="max-content" 
                        align="center" 
                        font="'Playfair Display', serif" 
                        bcolor="unset" 
                        color="black" 
                        lColor="black" 
                        data-sal="slide-down"
                        data-sal-delay="300"
                        data-sal-easing="ease-out-quad"
                    >
                        Skills
                    </H1WithLineAbove>
                    <Skills>
                        <Skill name="JavaScript" level={3}></Skill>
                        <Skill name="HTML & CSS" level={3}></Skill>
                        <Skill name="PHP" level={3}></Skill>
                        <Skill name="Python" level={3}></Skill>
                        <Skill name="C++" level={4}></Skill>
                        <Skill name="Java" level={3}></Skill>
                        <Skill name="English (IELTS 7.0)" level={4}></Skill>
                        <Skill name="Chinese" level={2}></Skill>
                        <Skill name="Vietnamese" level={5}></Skill>
                        <Skill name="Thai" level={1}></Skill>
                    </Skills>
                </PartialContainer>

                <PartialContainer className='section' id="award">
                    <H1WithLineAbove 
                        width="max-content" 
                        align="center" 
                        font="'Playfair Display', serif" 
                        bcolor="unset" 
                        color="black" 
                        lColor="black" 
                        data-sal="slide-down"
                        data-sal-delay="300"
                        data-sal-easing="ease-out-quad"
                    >
                        Awards
                    </H1WithLineAbove>
                    <SectionsContainer>
                        <AwardSection
                            year={"08/2022"}
                            title={"Participated in Build On Vietnam 2022"}
                            organizationalUnit={"Amazon Web Services"}
                        />
                        <AwardSection
                            year={"2020"}
                            title={"First Prize in IT Provincial Contest"}
                        />
                        <AwardSection
                            year={"2019"}
                            title={"Bronze Medal in IT National Northern Contest"}
                        />
                    </SectionsContainer>
                    
                </PartialContainer>

            </Section>
        </SecondContainer>
    )
}

export default Resume