import React, { useState, useEffect } from 'react';
import Logo from '../../img/MIRROR.png';
import styled from 'styled-components';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';

const HeaderContainer = styled.header`
    width: 100%;
    box-sizing: border-box;
    height: 80px;
    background-color: white;
    display: flex;
    padding: 10px 4.5vw;
    --sal-duration: 1s;
    justify-content: space-between;
    align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  width: 40%;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 900px) {
      display: none;
  }
`;

const NavMobileBtn = styled.svg`
  cursor: pointer;
  @media (min-width: 901px) {
      display: none;
  }
`;

const NavMobile = styled.div`
  align-items: center;
  background-color: #fff;
  box-shadow: -2px 0 5px #00000080;
  opacity: ${props => props.open ? "1" : "0"};
  visibility: ${props => props.open ? "visible" : "hidden"};
  flex-direction: column;
  gap: 0;
  height: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(0);
  width: 200px;
  transition: opacity .15s linear;
  z-index: ${props => props.open ? "2" : "-1"};
  @media (min-width: 901px) {
      display: none;
  }
`;

const Backdrop = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: fixed;
  opacity: ${props => props.open ? "1" : "0"};
  visibility: ${props => props.open ? "visible" : "hidden"};
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .15s linear;
  z-index: ${props => props.open ? "1" : "-1"};
  background-color: rgba(0, 0, 0, .6);
  @media (min-width: 901px) {
      display: none;
  }
`;

const Button = styled.button`
  font-family: 'Martel Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  &:focus{
    outline: none;
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: 50%;
    width: 0;
    height: 3px;
    background-color: black;
    transition: width 0.3s ease-in-out;
    transform: translateX(-50%);
  }
  &:hover::after,
  &:focus::after {
  width: 100%;
}
`;

const ButtonMobile = styled.div`
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  display: block;
  font-family: Martel Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  padding: 12px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
  z-index: 4;
  &:hover {
      background-color: black;
      color: white;
      text-decoration: none;
  }
`;

const NavMobileCloseButton = styled.div`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 50%;
  bottom: 0;
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 45px;
  height: 50px;
  justify-content: center;
  left: 0;
  line-height: 45px;
  margin: 0 auto;
  padding-bottom: 1px;
  padding-right: 1px;
  position: absolute;
  right: 0;
  text-align: center;
  transform: translateY(60px);
  vertical-align: middle;
  width: 50px;
`;

const Img = styled.img`
  width: 180px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  &:hover{
    scale: 1.1;
  }
`;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    sal();
  }, []);

  const handleClick = (elementId) => {
    const element = document.getElementById(elementId);
    console.log(element);
    if (element) {
      window.scroll({top: element.offsetTop , behavior: 'smooth'});
    }
  }

  return (
    <HeaderContainer
      data-sal="slide-right"
      data-sal-delay="300"
      data-sal-easing="ease-out-quad"
    >
        <Img src={Logo} alt="Home" />
        <Nav>
          <Button onClick={() => handleClick("resume")}>RESUME</Button>
          <Button onClick={() => handleClick("project")}>PROJECT</Button>
          <Button onClick={() => handleClick("contact")}>CONTACT</Button>
        </Nav>
        <NavMobileBtn xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16" onClick={toggleMobileMenu}>
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </NavMobileBtn>
        <Backdrop open={isNavOpen} onClick={toggleMobileMenu}/>
        <NavMobile open={isNavOpen} >
          <ButtonMobile onClick={() => {handleClick("resume"); toggleMobileMenu()}}>RESUME</ButtonMobile>
          <ButtonMobile onClick={() => {handleClick("project"); toggleMobileMenu()}}>PROJECT</ButtonMobile>
          <ButtonMobile onClick={() => {handleClick("contact"); toggleMobileMenu()}}>CONTACT</ButtonMobile>
          <NavMobileCloseButton onClick={toggleMobileMenu} >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
          </NavMobileCloseButton>
        </NavMobile>
    </HeaderContainer>
  )
}

export default Header;